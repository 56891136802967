import { configureStore, combineReducers, Action, ThunkAction } from '@reduxjs/toolkit';
import listenerMiddleware from './listenerMiddleware'; 
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import {
  userReducer,
  videoReducer,
  audioReducer,
  songListReducer,
  filterReducer,
  uploadReducer,
  downloadReducer,
  trackReferenceReducer,
  favoritesReducer,
  dialogReducer
} from './slices';

const rootReducer = combineReducers({
  user: userReducer,
  video: videoReducer,
  audio: audioReducer,
  songList: songListReducer, 
  filters: filterReducer,
  upload: uploadReducer,
  download: downloadReducer,
  trackReference: trackReferenceReducer,
  favorites: favoritesReducer,
  dialog: dialogReducer
});

const persistConfig = {
  key: 'root',
  storage,
  // Optionally, specify which reducers you want to persist
  whitelist: ['user'], 
  // whitelist: ['songList'], 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([listenerMiddleware]),  
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
